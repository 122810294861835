import { useContext, useState } from 'react';
//import { Popup } from 'semantic-ui-react';
import { SimulationContext } from '../../../contexts/SimulationContext';
import { NETWORK_INFO } from '../../../helpers/constants/application.constants';
import {
  defaultCurrencyIcon,
  verifiedIcon,
} from '../../../helpers/icons/icons';
import { IERC20BalanceChange } from '../../../helpers/interfaces/dataTypes.interface';
import { useAddressLabels } from '../../../hooks/useAddressLabel';
//import Address from '../../Atoms/Address';
//import CurrencyContainer from '../../Atoms/CurrencyContainer';
import TransactionRowContainer from '../../Containers/TransactionRowContainer';

export interface IERC20TransferProps {
  balanceChange: IERC20BalanceChange;
}

const ERC20Transfer: React.FC<IERC20TransferProps> = ({ balanceChange }) => {
  const { eipGlobalInformation } = useContext(SimulationContext);
  const network = eipGlobalInformation.network;

  const { /*data: addressLabelMap,*/ loading } = useAddressLabels(
    [balanceChange.to],
    network,
    undefined,
    5
  );

  //  TODO: As we add scam token indicators will need to update this
  /*const tokenStatus = balanceChange.verified ? (
    <Popup size="mini" position="top left" trigger={verifiedIcon}>
      Fire verifies tokens from TokenList
    </Popup>
  ) : (
    <></>
  );*/

  const isNative = balanceChange.type === 'eth';

  const isValidLogo = !!(
    balanceChange.logo && balanceChange.logo !== 'unknown'
  );

  const Logo: React.FC = () => {
    const [didImageError, setDidImageError] = useState<boolean>(false);
    if (isNative) {
      return NETWORK_INFO[network].icon;
    }

    const ContentImage: JSX.Element = (
      <img
        className="h-[32px] w-[32px] rounded-full"
        src={balanceChange?.logo ?? 'invalid'}
        alt={balanceChange.symbol}
        onError={() => setDidImageError(true)}
        crossOrigin="anonymous"
      />
    );
    if (isValidLogo && !didImageError) {
      return <>{ContentImage}</>;
    }
    return <>{defaultCurrencyIcon}</>;
  };

  /*
  <div className="grid grid-cols-10 justify-between">
        <div className="flex flex-col justify-between p-0.5 col-span-6">
          <div className="flex flex-row items-center">
            <Logo />
            <span className="font-ClashDisplay text-xl font-medium text-secondary-800">
              {balanceChange.symbol}
            </span>
            <div className="ml-1.5 mx-auto">{tokenStatus}</div>
          </div>
          <div className="flex flex-row font-Manrope w-full font-medium text-sm text-secondary-800 self-end overflow-hidden whitespace-nowrap text-overflow-ellipsis">
            {'To: '}
            <Address
              className="ml-1 overflow-hidden whitespace-nowrap text-overflow-ellipsis"
              labeledAddress={addressLabelMap?.get(balanceChange.to)}
              address={balanceChange.to}
              network={network}
            />
          </div>
        </div>
        <CurrencyContainer
          usdValue={balanceChange.usdValue}
          value={balanceChange.value}
        />
      </div>
      */
  return (
    <TransactionRowContainer loading={loading}>
      <div className="interacting-item-label">
        <div className="interacting-item-token">
          <Logo />
        </div>
        <div>
          <span className="interacting-item-token-symbol">
            {balanceChange.symbol}
            {balanceChange.verified ? verifiedIcon : null}
          </span>
        </div>
      </div>
      <div className="interacting-item-value">
        <div>{balanceChange.value}</div>
      </div>
    </TransactionRowContainer>
  );
};

export default ERC20Transfer;
